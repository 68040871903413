import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ComponentStore } from '@ngrx/component-store';
import {
  MissionTrackingState,
  Rewards,
  MissionTracking,
  MissionTrackingList,
  MissionLandingDetail,
  PointBalance,
} from '../../models';
import { Action, NgRxState } from '../../modules';

const initialState: MissionTrackingState = {
  missionTrackingId: undefined,
  missionLandingDetail: undefined,
  missionTrackingList: undefined,
  selMissionTrackingList: undefined,
  missionTracking: undefined,
  pointBalance: undefined,
  reward: undefined,
};

@Injectable({ providedIn: 'root' })
export class MissionTrackingStore extends ComponentStore<MissionTrackingState> {
  static initialState: MissionTrackingState = initialState;

  constructor(private ngrxStore: Store<NgRxState>) {
    super(initialState);
  }

  readonly missionTrackingId$ = this.select((state) => state.missionTrackingId);
  readonly missionLandingDetail$ = this.select(
    (state) => state.missionLandingDetail
  );
  readonly missionTrackingList$ = this.select(
    (state) => state.missionTrackingList
  );
  readonly selMissionTrackingList$ = this.select(
    (state) => state.selMissionTrackingList
  );
  readonly missionTracking$ = this.select((state) => state.missionTracking);
  readonly pointBalance$ = this.select((state) => state.pointBalance);
  readonly reward$ = this.select((state) => state.reward);

  readonly setMissionTrackingId = this.updater(
    (state, missionTrackingId: number) => {
      this.ngrxStore.dispatch(
        Action.MissionTrackingAction.SetMissionTrackingId({
          ...state,
          missionTrackingId,
        })
      );
      return { ...state, missionTrackingId };
    }
  );

  readonly setMissionLandingDetail = this.updater(
    (state, missionLandingDetail: MissionLandingDetail) => {
      this.ngrxStore.dispatch(
        Action.MissionTrackingAction.SetMissionLandingDetail({
          ...state,
          missionLandingDetail,
        })
      );
      return { ...state, missionLandingDetail };
    }
  );

  readonly setMissionTrackingList = this.updater(
    (state, missionTrackingList: MissionTrackingList[]) => {
      this.ngrxStore.dispatch(
        Action.MissionTrackingAction.SetMissionTrackingList({
          ...state,
          missionTrackingList,
        })
      );
      return { ...state, missionTrackingList };
    }
  );

  readonly setSelMissionTrackingList = this.updater(
    (state, selMissionTrackingList: MissionTrackingList) => {
      this.ngrxStore.dispatch(
        Action.MissionTrackingAction.SetSelMissionTrackingList({
          ...state,
          selMissionTrackingList,
        })
      );
      return { ...state, selMissionTrackingList };
    }
  );

  readonly setMissionTracking = this.updater(
    (state, missionTracking: MissionTracking) => {
      this.ngrxStore.dispatch(
        Action.MissionTrackingAction.SetMissionTracking({
          ...state,
          missionTracking,
        })
      );
      return { ...state, missionTracking };
    }
  );

  readonly setPointBalance = this.updater(
    (state, pointBalance: PointBalance) => {
      this.ngrxStore.dispatch(
        Action.MissionTrackingAction.SetPointBalance({
          ...state,
          pointBalance,
        })
      );
      return { ...state, pointBalance };
    }
  );

  readonly setReward = this.updater((state, reward: Rewards) => {
    this.ngrxStore.dispatch(
      Action.MissionTrackingAction.SetReward({
        ...state,
        reward,
      })
    );
    return { ...state, reward };
  });

  readonly reset = this.updater(() => {
    this.ngrxStore.dispatch(Action.LeaderboardAction.Reset());
    return { ...initialState };
  });
}
